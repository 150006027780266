import React from 'react'
import styles from './navbar.module.scss'
import { ReactComponent as Mail_Logo } from '../../../assets/svg/mail_logo.svg'
import { Link } from 'react-router-dom'
import { downloadService } from '../../../app.registration';
import { PLATFORM } from '../../constants/constants';
import { ROUTES } from '../../constants/routes';
import { useOsStore } from '../../../stateManager/OsStore';

import useNavbarHook from './navbar.hook';
import { useDownloadHook } from '../../hooks/download.hook';



function Navbar() {

    const {
        handleBuildDownload, }
        = useDownloadHook()

    const {
        os
    } = useNavbarHook()


    return (
        <nav className={styles.navbar}>
            <div className={styles.mail_logoContainer}>
                <Link to="">
                    <Mail_Logo className={styles.mail_Logo} />
                </Link>
            </div>
            <div className={styles.downloadBttnContainer}>
                <Link to="">
                    <button onClick={() => handleBuildDownload(os)} className={styles.downloadBttn}>Download</button>
                </Link>
            </div>
        </nav>
    )
}

export { Navbar }