import React, { useEffect, useState } from 'react'
import styles from './home.module.scss'
import ZunuMailHeader from '../../assets/webp/mail_header.webp'
import PixelDesktop from '../../assets/webp/pixel_desktop.webp'
import { ReactComponent as WindowsLogo } from '../../assets/svg/windows_logo.svg'
import { ReactComponent as MacLogo } from '../../assets/svg/mac_logo.svg'
import { ReactComponent as LinuxLogo } from '../../assets/svg/linux_logo.svg'
import { ReactComponent as AppStoreLogo } from '../../assets/svg/appStore_logo.svg'
import { ReactComponent as PlayStoreLogo } from '../../assets/svg/playStore_logo.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as MaiLFeatureIcon1 } from '../../assets/svg/mailFeature_Icon1.svg'
import { ReactComponent as MaiLFeatureIcon2 } from '../../assets/svg/mailFeature_icon2.svg'
import { ReactComponent as MaiLFeatureIcon3 } from '../../assets/svg/mailFeature_Icon3.svg'
import { ReactComponent as MaiLFeatureIcon4 } from '../../assets/svg/mailFeature_Icon4.svg'
import { ReactComponent as MaiLFeatureIcon5 } from '../../assets/svg/mailFeature_Icon5.svg'
import { ReactComponent as MaiLFeatureIcon6 } from '../../assets/svg/mailFeature_Icon6.svg'
import { ReactComponent as MaiLFeatureIcon7 } from '../../assets/svg/mailFeature_Icon7.svg'
import { ReactComponent as MaiLFeatureIcon8 } from '../../assets/svg/mailFeature_Icon8.svg'
import Mail_Illustration1 from '../../assets/webp/mail_illustration1.webp'
import Mail_Illustration2 from '../../assets/webp/mail_illustration2.webp'
import Mail_Illustration3 from '../../assets/webp/mail_illustration3.webp'
import Mail_Illustration4 from '../../assets/webp/mail_illustration4.webp'
import Mail_SetApart from '../../assets/webp/mail_setApart.webp'
import Pixel_Mobile from '../../assets/webp/pixel_mobile.webp'
import Mail_banner from '../../assets/webp/mail_banner_image_mobile.webp'
import Mail_SetApart_Mobile from '../../assets/webp/mail_setApart_mobile.webp'
import MacDesktop from '../../assets/webp/macBook_footer.webp'
import MacBook_mobile from '../../assets/webp/macBook_mobile_footer.webp'
import simplified_privacy1 from '../../assets/webp/simplified_privacy1.webp'
import Simplified_privacy2 from '../../assets/webp/simplified_privacy2.webp'
import Simplified_privacy3 from '../../assets/webp/simplified_Privacy3.webp'
import { ReactComponent as BlueTick } from '../../assets/svg/blueTickMail.svg'
import { ReactComponent as EmailLogo } from '../../assets/svg/Email_services.svg'
import { ReactComponent as OutlookLogo } from '../../assets/svg/outlook_services.svg'
import { DEVICE, PLATFORM, THEME } from '../../_shared/constants/constants'
import { useDownloadHook } from '../../_shared/hooks/download.hook'
import { useDeviceStore } from '../../stateManager/DeviceStore'
import { useOsStore } from '../../stateManager/OsStore'

import { lazily } from 'react-lazily';
const { FloaterDownload } = lazily(() => import('../../_shared/components/FloaterDownload/floaterDownload.component'));


function Home() {


    const { device, setDevice } = useDeviceStore();
    const {
        handleBuildDownload,
    } = useDownloadHook();
    const { os } = useOsStore();


    function handleResize() {
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE)
        }
        else if (window.innerWidth >= 1200) {
            setDevice(DEVICE.DESKTOP)
        }
        else {
            setDevice(DEVICE.TABLET)
        }
    }


    useEffect(() => {
        window.addEventListener('resize', handleResize)
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE)
        }
        else if (window.innerWidth >= 1200) {
            setDevice(DEVICE.DESKTOP)
        }
        else {
            setDevice(DEVICE.TABLET)
        }
    }, [])


    return (
        <div className={styles.homeContainer}>

            <FloaterDownload title='To protect your emails' buttonText='Download' mode={`${THEME.DARK}`}/>

            <div className={styles.headerContainer}>
                <div className={styles.header}>
                    <div className={styles.heading}>
                        Keep your private life <span>private.</span>
                    </div>
                    <div className={styles.subHeading}>
                        No Harmful AI. No Data Mining. Your Emails are Dark Web Safe.
                    </div>

                </div>
                <div className={styles.headerImg}>
                    <img src={device == DEVICE.MOBILE ? `${Mail_banner}` : `${ZunuMailHeader}`} alt='Zunu mail Header' />
                </div>
            </div>

            <div className={styles.mailContentContainer}>
                <div className={styles.downloadContainer}>
                    <div className={styles.downloadLeftContainer}>
                        <p className={styles.downloadHeader}>Ultimate Email Privacy on the Cloud, on Device and Everywhere Beyond</p>
                        {device !== DEVICE.MOBILE &&
                            <div className={styles.download_Os_container}>
                                <div className={styles.downloadBtnContainer}>
                                    <button className={styles.downloadBtn} onClick={() => handleBuildDownload(os)}>Download Now</button>
                                </div>

                                <div className={styles.osLogoContainer}>
                                    <Link to={""} onClick={() => handleBuildDownload(PLATFORM.WIN)}><WindowsLogo className={styles.osLogos} /></Link>
                                    <Link to={""} onClick={() => handleBuildDownload(PLATFORM.MAC)}><MacLogo className={styles.osLogos} /></Link>
                                    <Link to={""} onClick={() => handleBuildDownload(PLATFORM.LINUX22)}><LinuxLogo className={styles.osLogos} /></Link>
                                    <Link to={""} onClick={() => handleBuildDownload(PLATFORM.IOS)}><AppStoreLogo className={styles.osLogos} /></Link>
                                    <Link to={""} onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.downloadImgContainer}>
                        <img src={device == DEVICE.MOBILE ? `${Pixel_Mobile}` : `${PixelDesktop}`} />
                    </div>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.osLogoContainer}>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.WIN)}><WindowsLogo className={styles.osLogos} /></Link>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.MAC)}><MacLogo className={styles.osLogos} /></Link>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.LINUX22)}><LinuxLogo className={styles.osLogos} /></Link>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.IOS)}><AppStoreLogo className={styles.osLogos} /></Link>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                        </div>
                    }
                </div>

            </div>

            <div className={`${styles.mailFeatures_Container}  ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Integrate Accounts. Encrypt. Email.
                </div>
                <div className={styles.mail_Features_content}>
                    <div className={styles.featureContainer}>
                        <div className={styles.mail_feature}>
                            <MaiLFeatureIcon1 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Manage All Emails in One Place.
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <MaiLFeatureIcon2 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Stay Secure, Even If Email Account is Breached.
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <MaiLFeatureIcon3 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Encrypted Drafts.
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <MaiLFeatureIcon4 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Search on Encrypted Emails.
                            </p>
                        </div>
                    </div>
                    <div className={styles.featureContainer}>
                        <div className={styles.mail_feature}>
                            <MaiLFeatureIcon5 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Emails Are Safe from AI and Dark Web Threats.
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <MaiLFeatureIcon6 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Instant Password-less Sign-in Across All Devices.
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <MaiLFeatureIcon7 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Emails are secure when the device Is Lost, Stolen, or Compromised.
                            </p>
                        </div>
                        <div className={styles.mail_feature}>
                            <MaiLFeatureIcon8 className={styles.featureIcons} />
                            <p className={styles.featureDescription}>
                                Attachments and Emails are protected from 3rd Party Apps with File Storage Access.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.simplifiedPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={styles.simplifiedPrivacyChild}>
                    <div className={styles.simplifiedHeader}>
                        <div className={styles.mailFeatures_header}>
                            Simplified Privacy:
                        </div>
                        <div className={styles.mailFeatureSubHeader}>Communicate Privately using your Existing Email Accounts.</div>
                    </div>
                    <div className={styles.privacyContainer}>
                        <div className={styles.privacyContent}>
                            <div className={styles.privacyEmail}>
                                <div className={styles.privacyHeaderContainer}>
                                    <p className={styles.privacyEmailHeader}> Add an email service provider</p>
                                    <p className={styles.prvacyEmailSubHeader}>Please select an email service provider that you want to use with Zunu Mail</p>
                                </div>
                                <div className={styles.accountsBtnContainer}>
                                    <div className={styles.mailAccountContainer}>
                                        <div className={styles.mailAccount1}>
                                            <BlueTick className={styles.blueTick} />
                                            <EmailLogo className={styles.email_Logo} />
                                            <p className={styles.EmailText}>Gmail Accounts</p>
                                        </div>
                                        <div className={styles.mailAccount2}>
                                            <div className={styles.circle}></div>
                                            <OutlookLogo className={styles.email_Logo} />
                                            <p className={styles.EmailText}>Outlook Accounts</p>
                                        </div>

                                    </div>
                                    <div className={styles.proceedBtnContainer}>
                                        <button className={styles.proceedBtn}>Proceed</button>
                                    </div>
                                </div>

                            </div>
                            <div className={styles.privacyTextContainer}>
                                Integrate Gmail and Outlook accounts into Zunu Mail.
                            </div>

                        </div>
                        <div className={styles.privacyContent2}>
                            <img src={Simplified_privacy2} alt='add Account' />
                        </div>

                        <div className={styles.privacyContent3}>
                            <img src={Simplified_privacy3} alt='mail' />
                        </div>
                    </div>

                </div>

            </div>
            <div className={`${styles.illustrationContainer} ${styles.mailContentContainer}`}>
                <div className={styles.illustrationText}>
                    <p className={styles.illustrationHeader}>Email Privacy from the Go, with Encrypted Drafts</p>
                </div>
                <div className={styles.illustrationImage}>
                    <img src={Mail_Illustration1} alt='mail illustration' />
                </div>
            </div>
            <div className={`${styles.mailPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Stress Free Email Privacy.
                </div>
                <div className={styles.ilustrationImgContainer}>
                    <div className={styles.illustrationImg}>
                        <img src={Mail_Illustration2} alt='illustration' />
                    </div>
                    <div className={styles.illustrationImg1}>
                        <img src={Mail_Illustration3} alt='illustration' />
                    </div>
                </div>
            </div>
            <div className={`${styles.mailPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Search on top of Encrypted Emails.
                </div>
                <div className={styles.illustration4img}>
                    <img src={Mail_Illustration4} alt='illustration' />
                </div>
            </div>

            <div className={`${styles.setUsApartContainer} ${styles.mailContentContainer}`}>
                <div className={styles.setApartHeader}>
                    <div className={styles.mailFeatures_header}>
                        What sets us Apart?
                    </div>
                    <div className={styles.mailFeatureSubHeader}>Our ultimate robust Device-to-Device End-to-End Encryption for 100% Privacy.</div>

                </div>
                <div className={styles.setApartImg}>
                    <img src={device == DEVICE.MOBILE ? `${Mail_SetApart_Mobile}` : `${Mail_SetApart}`} alt='illustration' />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <div className={styles.footerLeftContainer}>
                    <p className={styles.footerText_header}>Ultimate Email Privacy on the Cloud, on Device and Everywhere Beyond</p>
                    <div className={styles.footerDownload_container}>
                        {device !== DEVICE.MOBILE &&
                            <div className={styles.footerBtn_container}>
                                <button className={styles.FooterDownloadBtn} onClick={() => handleBuildDownload(os)}>Download Now</button>
                            </div>
                        }
                        <div className={styles.footerLogoContainer}>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.WIN)}><WindowsLogo className={styles.osLogos} /></Link>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.MAC)}><MacLogo className={styles.osLogos} /></Link>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.LINUX22)}><LinuxLogo className={styles.osLogos} /></Link>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.IOS)}><AppStoreLogo className={styles.osLogos} /></Link>
                            <Link to={""} onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                        </div>
                    </div>
                </div>
                <div className={styles.footerImgContainer}>
                    <img src={device == DEVICE.MOBILE ? `${MacBook_mobile}` : `${MacDesktop}`} alt='pixel mobile' />
                </div>
            </div>
        </div>
    )
}

export { Home }